@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/utilities/api";

.compactModalityControl {
  @extend .me-3;
  @extend .mb-2;
  @extend .mb-lg-0;
  @extend .flex-shrink-0;
}

.expandedModalityControl {
  @extend .mb-3
}

.compactSearchControlGroup {
  @extend .flex-grow-1;
  @extend .d-md-flex;
  @extend .w-100;
}

.searchControl {
  z-index: 100;
}
