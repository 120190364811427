@import "~bootstrap/scss/functions";
@import "~bootswatch/dist/flatly/variables";
@import "~bootstrap/scss/variables";

.searchControl {
  outline: none;
  border-radius: $input-border-radius;
  width: 100%;
  border: solid 1px hsl(0, 0%, 80%);
  padding: 18px;
  color: $input-color;

  &.compact {
    padding: 10px 10px !important;
  }

  &:focus {
    box-shadow: 0 0 0 2px $primary;
  }
}
